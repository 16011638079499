/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable, NgZone, EventEmitter, Output, ɵConsole } from '@angular/core';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';

import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import * as AuthEnums from './authenticationstates.enum';
import { BackendService } from '../services/backend.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public $userStatus: EventEmitter<any>;
  public userStatus: AuthEnums.Authenticationstates;

  public firstname: string; //registration only
  public email: string; //registration only

  public loading;

  constructor(
    public afAuth: AngularFireAuth // Inject Firebase auth service
    ,public router: Router
    ,public ngZone: NgZone // NgZone service to remove outside scope warning
    ,public toastController: ToastController
    , public bs: BackendService
    //, public us: UserService
    , private http: HttpClient
  ) {
    this.loading = false;
    this.$userStatus = new EventEmitter<any>();
    this.userStatus = AuthEnums.Authenticationstates.DEFAULT;
    this.firstname = '';

    //-- Assign new userState to userState
    this.$userStatus.subscribe(state => {
      this.userStatus = state;
    });
  }

  CheckEmail(email){
    this.loading = true;
    return this.afAuth.fetchSignInMethodsForEmail(email)
    .then(async (result) => {
      this.loading = false;
      //console.log(result);
      if(result.length>0){

        //-- Konto existiert ----
        if(result.includes('password')){
          this.userStatus = AuthEnums.Authenticationstates.LOGIN;
        }
        else if(result.includes('google.com')){
          const toast = await this.toastController.create({
            message: 'Es existiert bereits ein Konto welches mit Google registriert wurde, bitte unten auf den Knopf Anmelden mit Google klicken'
            ,duration: 5000
            ,color: 'secondary'
            ,position: 'top'
          });
          toast.present();
        }
      }
      else{
        this.userStatus = AuthEnums.Authenticationstates.REGISTER;
      }

    }).catch(async (error) => {
      this.handleErrors(error);

    });


  }
  // Sign in with email/password
  SignIn(email, password) {
    this.loading = true;

    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.router.navigate(['home']);

      }).catch(async (error) => {
        this.handleErrors(error);
      });
  }

  // Sign up with email/password
  SignUp(firstname, email, password, firma, lastname, tel) {
    this.loading = true;

    this.firstname = firstname;
    this.email = email;

    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        //-- Add User to BX Backend ------------
        const params = JSON.stringify({
          provideruid: result.user.uid,
          tel,
          firma,
          firstname,
          lastname
        });
        //console.log('register user');
        this.http.post(this.bs.baseurl+'users/register',params).toPromise()
        .then((data) => {
          //console.log('user registration ok:');
          //console.log(data);
        }).catch(async (error) => {
          //console.log('user registration error:');
          //console.log(error);
        });

        this.SendVerificationMail();
        //this.us.setUserData(result.user);
      }).catch(async (error) => {
        this.handleErrors(error);
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser.then((user) => user.sendEmailVerification());
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    this.loading = true;

    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(async () => {
      this.loading = false;

      const toast = await this.toastController.create({
        message: 'Eine E-Mail mit Link zum zurücksetzen des Passworts wurde verschickt, bitte prüfe den Posteingang'
        ,duration: 4500
        ,color: 'success'
        ,position: 'top'
      });
      toast.present();
    }).catch(async (error) => {
      this.handleErrors(error);
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    this.loading = true;
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  AppleAuth(){
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    this.loading = true;
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {

    }).catch((error) => {
      this.handleErrors(error);
      //window.alert(error)
    });
  }

  // Sign out
  SignOut() {
    this.loading = true;
    return this.afAuth.signOut().then(() => {
      this.loading = false;
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    });
  }

  ResetPassword(email, password, oobCode){
    this.afAuth.confirmPasswordReset(oobCode, password)
    .then(async (result) => {
      //console.log(result);
      this.SignIn(email, password);

    }).catch(async (error) => {
      //error.code
      //error.message
      this.handleErrors(error);

    });
  }

  public setuserStatus(status: AuthEnums.Authenticationstates){
    this.userStatus = status;
  }

  public async handleFbAuthActions(ars: ActivatedRouteSnapshot){
    const mode = ars.queryParamMap.get('mode');
    let result: any;
    result = {};

    return new Promise((resolve, reject) => {
      if(mode && mode=='resetPassword'){
        const oobCode = ars.queryParamMap.get('oobCode');
        if(oobCode){
          this.afAuth.verifyPasswordResetCode(oobCode)
          .then((email) => {
            result.mode = mode;
            result.email = email;
            result.oobCode = oobCode;

            resolve(result);

          })
          .catch(async () => {
            const toast = await this.toastController.create({
              message: 'Code zum zurücksetzen des Passworts ist nicht mehr gültig, bitte nochmals "Passwort vergessen" ausführen.'
              ,duration: 4500
              ,color: 'success'
              ,position: 'top'
            });
            toast.present();
            this.router.navigate(['login']);
            reject();
          });
        }

      }
      else if(mode && mode=='verifyEmail'){
        const oobCode = ars.queryParamMap.get('oobCode');
        this.afAuth.applyActionCode(oobCode)
        .then(async (res) => {

          const toast = await this.toastController.create({
            message: 'E-Mail erfolgreich verifiziert.'
            ,duration: 4500
            ,color: 'success'
            ,position: 'top'
          });
          toast.present();
          //console.log(res);
          result.mode = mode;

          this.router.navigate(['newregistration']);


          resolve(result);

        })
        .catch(async () => {
          reject();
        });
      }
      else if(mode && mode=='recoverEmail'){
        const oobCode = ars.queryParamMap.get('oobCode');
        const lang = ars.queryParamMap.get('lang');
        this.handleRecoverEmail(this.afAuth, oobCode, lang);
        //this.afAuth.applyActionCode(oobCode);

      }
      else{
        this.router.navigate(['login']);
        reject();
      }
    });



  }

  handleRecoverEmail(auth, actionCode, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    let restoredEmail = null;
    let previousEmail = null;
    // Confirm the action code is valid.
    auth.checkActionCode( actionCode).then((info) => {

      // Get the restored email address.
      restoredEmail = info.data.email;
      previousEmail = info.data.previousEmail;
      //console.log(info);

      // Revert to the old email.
      return auth.applyActionCode( actionCode);
    }).then(() => {
      // Account email reverted to restoredEmail
      this.bs.showToast('E-Mail wurde erfolgreich wieder auf alten Wert zurückgesetzt. Aus Sicherheitsgründen sollte das Passwort geändert werden!', 7000, 'success');

      //-- Interne E-Mail ebenfalls zurücksetzenb
      //let pbkunde = this.us.userData.pbkunde;
      //pbkunde.email = restoredEmail;
      return auth.currentUser.then((user) => {
        //console.log('current user');
        //console.log(user);
        return new Promise(resolve => {
          const params = JSON.stringify({
            restoredEmail
            ,previousEmail
          });
          //-- Update Kunde wenn E-Mail update erfolgreich war ------
          const url = this.bs.baseurl + 'pbkunden/recovermail';
          this.http.post(url,params)
            .subscribe(async data => {
              const result: any = data;
              if(result.success){

                auth.sendPasswordResetEmail(restoredEmail).then(() => {
                  // Password reset confirmation sent. Ask user to check their email.

                }).catch((error) => {
                  // Error encountered while sending password reset code.
                });
                this.router.navigate(['login']);
              }
              resolve(true);
            }, error => {
              resolve(false);
          });
        });
      });

      // TODO: Display a confirmation message to the user.

      // You might also want to give the user the option to reset their password
      // in case the account was compromised:

    }).catch((error) => {
      // Invalid code.
    });
  }

  async handleErrors(error){
    /*
    code: "auth/argument-error"
    message: "fetchSignInMethodsForEmail failed: First argument \"email\" must be a valid string."

    code: "auth/argument-error"
    message: "createUserWithEmailAndPassword failed: Second argument \"password\" must be a valid string."

    code: "auth/weak-password"
    message: "Password should be at least 6 characters"

    code: "auth/wrong-password"
    message: "The password is invalid or the user does not have a password."

    code: "auth/popup-closed-by-user"
    message: "The popup has been closed by the user before finalizing the operation."

    code: "auth/invalid-email"
    message: "The email address is badly formatted."
    */
   this.loading = false;
    let message = error;
    if(error && error.code){
      switch(error.code) {
        case 'auth/argument-error':

          if(error.message && error.message && error.message.includes('fetchSignInMethodsForEmail') ){
            message ='Gültige E-Mail eintragen';
          }
          else if(error.message && error.message && error.message.includes('createUserWithEmailAndPassword')){
            message = 'Passwort muss länger als 5 Zeichen sein.';
          }
          else{
            message = error;
          }

        break;
        case 'auth/weak-password':
          message = 'Passwort muss länger als 5 Zeichen sein.';
        break;
        case 'auth/wrong-password':
          message = 'Passwort ist ungültig oder kein Passwort vorhanden.';
        break;
        case 'auth/invalid-email':
          message = 'Ungültige E-Mail';
        break;
        case 'auth/popup-closed-by-user':
          message = 'Popup wurde geschlossen bevor Login abgeschlossen war.';
        break;



    }

    console.error(error);
    const toast = await this.toastController.create({
        message
        ,duration: 4500
        ,color: 'danger'
        ,position: 'top'
      });
      toast.present();

    }
  }


}
