import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { HttpClient, HttpBackend, HttpHeaders, HttpParams } from '@angular/common/http';
import * as dayjs from 'dayjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class BackendService {
  public staging = environment.staging;
  public baseurl: string = 'https://api5'+this.staging+'.parkix.ch/app.php/';
  public portalurl: string = 'https://portal'+this.staging+'.parkix.ch/';
  public selfurl: string = 'https://px5'+this.staging+'.parkix.ch/';
  constructor(private toastCtrl: ToastController,) {
   }

  public async showToast(text: string, duration: number = 3000, color: string = 'primary'){
    const toast = await this.toastCtrl.create({
      message: text,
      position: 'top',
      color,
      duration
    });
    toast.present();
  }

  public getDistanceFromLatLonInKM(lat1,lon1,lat2,lon2) {
    const R = 6371; // Radius of the earth in m
    const dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2-lon1);
    const a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const d = R * c; // Distance in m
    return d;
  }

  public deg2rad(deg) {
    return deg * (Math.PI/180);
  }

  public random(len) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < len; i++)
      {text += possible.charAt(Math.floor(Math.random() * possible.length));}

    return text;
  }



  boolToText(value){
    if(value == 0){
      return 'nein';
    }
    else{
      return 'ja';
    }

  }

  public wc_hex_is_light(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
  }

  public invertcolor(color){
    if(this.wc_hex_is_light(color)){
      return '#000000';
    }
    else{
      return '#ffffff';
    }
  }







}
