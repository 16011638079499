/* eslint-disable eqeqeq */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class EinsatzplanService {
  public viewmode: string;
  public data: any;
  public datatoday: any;
  public todaytimeslot;
  constructor(private bs: BackendService, private http: HttpClient) {
    this.viewmode = 'planned';
    this.todaytimeslot = {datum:'', timeslots:[]};
  }

  public clear(){
    this.viewmode = 'planned';
    this.todaytimeslot = {datum:'', timeslots:[]};
    this.data = null;
    this.datatoday = null;
  }

  public load(): Promise<any>{
    return new Promise( resolve => {

        this.http.get(this.bs.baseurl+'einsatzplane/getassigned?mode='+this.viewmode).subscribe( (res: any) => {

          this.data = res.data;
          //console.log(res.data);
          resolve('');
        }, error => {
          console.log('HTTP Error, no answer ep-data.ts');
          console.log(error);
      });
    });
  }

  //public setTimeSlot(timeslot, active)
  public isTimeslotActive(timeslot) : boolean {
    for(let i= 0; i<this.todaytimeslot.timeslots.length; i++){

      if(this.todaytimeslot.timeslots[i].sollzeit==timeslot){
        return this.todaytimeslot.timeslots[i].active;
      }
    }
    return false;
  }

  public getToday(){
    //console.log("getToday");
    return new Promise(resolve => {
        this.http.get(this.bs.baseurl+'einsatzplane/getassigned?mode=today').subscribe( (res: any) => {
          //console.log(res.data);
          let tmp = res.data;
          //-- build local stored timeslotls ---------
          let buildtimeslots = false;
          
          if((tmp.length>0) && this.todaytimeslot.datum != tmp[0].datum){
            this.todaytimeslot.datum = tmp[0].datum;
            this.todaytimeslot.timeslots.lenght = 0;
            buildtimeslots = true;
          }

          for(let i=0; i<tmp.length; i++){
            //-- check if localtimeslots are old --------
            if(buildtimeslots){              
              this.todaytimeslot.timeslots.push({sollzeit:tmp[i].sollzeit, active: true});
            }
            
          }
          this.datatoday = tmp;
          resolve('');
        }, error => {
          console.log('HTTP Error, no answer ep-data.ts');
          console.log(error);
      });

    });
  }

}
