/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, EventEmitter} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BackendService } from './backend.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GrundstueckeService {
  public data;
  public loading;
  public loaded;
  private dataloading: Observable<any>;
  public $onGrundstueckeLoaded: EventEmitter<any>;

  public zonen;
  public zonenloaded;
  private zonendataloading: Observable<any>;
  public $onZonenLoaded: EventEmitter<any>;
  public verstoesse;  

  constructor(private bs: BackendService, private http: HttpClient) {
    this.data = [];
    this.zonen = [];
    this.zonenloaded = false;
    this.$onZonenLoaded = new EventEmitter<any>();
    this.$onGrundstueckeLoaded = new EventEmitter<any>();
    this.loaded = false;
    //this.load();
    //this.loadZonen();

  }
  
  public clear(){
    this.data.length = 0;
    this.zonen.length = 0;
    this.loaded = false;
    this.zonenloaded = false;
  }

  public load() {
    //-- Verstösse laden ------
    this.loadVerstoesse();
    if (this.data.length <= 0){
      this.loading = true;
      const url = this.bs.baseurl + 'grundstuecke/getall';
      this.dataloading = this.http.get(url);
      this.dataloading.subscribe((res: any) => {
        this.data = res.data;

        this.data.sort(function(a, b){
          if(a.kurzname < b.kurzname) { return -1; }
          if(a.kurzname > b.kurzname) { return 1; }
          return 0;
        });
        this.loading = false;
        this.loaded = true;
        this.$onGrundstueckeLoaded.emit();
      },
      (error: any) =>{
        this.loading = false;
      }
      );

    }
  }

  public async getMandatByGrundstueck(grundid: number): Promise<any>{
    if(this.data.length <= 0){
      return this.dataloading.toPromise()
      .then((val) => {
        const grundstuecke = val.data;
        return new Promise((resolve, reject) => {
          resolve(this.getMandatIdByGrundstueck(grundid, grundstuecke));
        });

      });
    }
    else{
      return new Promise((resolve, reject) => {
        resolve(this.getMandatIdByGrundstueck(grundid, this.data));
      });
    }
  }

  private getMandatIdByGrundstueck(mandid: number, data){
    const grundstueck = data.filter(function(elem) {
      return (elem.grundid === mandid );
    });
    if(grundstueck.length>0){
      return grundstueck[0].fkmandat;
    }
    else{
      return -1;
    }
  }

  public getGrundstueckName(grundstueckId: number){
    const grundstueck = this.getGrundstueck(grundstueckId);
    if(grundstueck){
      return grundstueck.name;
    }
    return grundstueckId;
  }
  public getGrundstueck(grundstueckId: number){
    if(this.data.length>0){
      let grundstueck = this.data.filter(function(elem) {
        return (elem.grundid === grundstueckId );
      });

      if(grundstueck && grundstueck.length>0){
        grundstueck = grundstueck[0];
        return grundstueck;
      }
      else{
        return null;
      }
    }
    else{
      return null;
    }
  }
  public getGrundstueckByKurzname(kurzname: string){
    if(this.data.length>0){
      let grundstueck = this.data.filter(function(elem) {
        return (elem.kurzname === kurzname );
      });

      if(grundstueck && grundstueck.length>0){
        grundstueck = grundstueck[0];
        return grundstueck;
      }
      else{
        return null;
      }
    }
    else{
      return null;
    }
  }

  public getZonenName(zonenId: number){
    if(this.zonen && this.zonen.length>0){
      let zone = this.zonen.filter(function(elem) {
        return (elem.id == zonenId );
      });

      if(zone && zone.length>0){
        zone = zone[0];
        return zone.name;
      }
      else{
        return zonenId;
      }
    }
    else{
      return zonenId;
    }
  }

  public isZonenSichtbar(zonenId: number){
    if(this.zonen && this.zonen.length>0){
      let zone = this.zonen.filter(function(elem) {
        return (elem.id === zonenId );
      });
      if(zone && zone.length>0){
        zone = zone[0];
        return (zone.sichtbar === 1);
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }

  loadKontrollinfo(grundid) {
    return new Promise(resolve => {

        this.http.get(this.bs.baseurl+'grundstuecke/loadkontrollinfo?grundid='+grundid).subscribe( (res: any) => {
          if(res.data){
            resolve(res.data.body);
          }
          else{
            resolve(null);
          }
        }, error => {
          console.log('HTTP Error, no answer info');
          console.log(error);
      });


    });
  }

  loadVerstoesse(){
    return new Promise(resolve => {

      this.http.get(this.bs.baseurl+'grundstuecke/verstoesse').subscribe( (res: any) => {
        if(res.data){
          //console.log(res.data);
          this.verstoesse = res.data;
        }
        else{
          resolve(null);
        }
      }, error => {
        console.log('HTTP Error, no answer info');
        console.log(error);
    });


  });
  }

}
