import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { AlertController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(public swUpdate: SwUpdate, public alertController: AlertController) {

    //console.log("Update Service");
    this.assignUpdates();
    if (swUpdate.isEnabled) {
      //console.log("Update Service enabled");

      setInterval(() => {
        this.checkUpdate();
      } , 1000 * 60);

    }
    else{
      console.log('Update Service disabled');
    }
  }

  public checkUpdate(): void{
    //console.log("check for update");
    this.swUpdate.checkForUpdate().then( value =>{
      //console.log("update checker");
      //console.log(value);
    });
  }

  public assignUpdates(): void {
    if (this.swUpdate.isEnabled) {
      //console.log("subscripe updatews");
      this.swUpdate.versionUpdates.subscribe( async (event) => {
        if (event.type === "VERSION_READY") {
          const alert = await this.alertController.create({
            header: 'Update!',
            // eslint-disable-next-line max-len
            message: 'Ein neues Update ist verfügbar. Das Update sollte durchgeführt werden, ausser Sie führen gerade eine Parkkontrolle durch und haben diese noch nicht abgeschlossen.',
            buttons: [
              {
                text: 'Abbrechen',
                role: 'cancel',
                cssClass: 'secondary',
              }, {
                text: 'Update',
                handler: () => {
                  window.location.reload();
                },
              },
            ],
          });
          await alert.present();
        }
      });
      /*
      this.swUpdate.available.subscribe(async () => {

        const alert = await this.alertController.create({
          header: 'Update!',
          // eslint-disable-next-line max-len
          message: 'Ein neues Update ist verfügbar. Das Update sollte durchgeführt werden, ausser Sie führen gerade eine Parkkontrolle durch und haben diese noch nicht abgeschlossen.',
          buttons: [
            {
              text: 'Abbrechen',
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: 'Update',
              handler: () => {
                window.location.reload();
              },
            },
          ],
        });
        await alert.present();
      });
      */
    }
  }
}
