import { Component, NgZone } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './guard/auth.guard';
import { Router, RouterEvent } from '@angular/router';
import { ActivatedRoute, RouterState, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from './services/user.service';
import { GrundstueckeService } from './services/grundstuecke.service';
import { EinsatzplanService } from './services/einsatzplan.service';
import { UpdateService } from './services/update.service';
import { Authenticationstates } from './auth/authenticationstates.enum';
import { BackendService } from './services/backend.service';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { MenuController } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public activePath = '';
  public appPages = [];
  public showMenu = true;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public Authenticationstates = Authenticationstates;
  public appPagesLoggedOut = [

    {
      title: 'Anmelden',
      url: '/login',
      icon: 'log-in'
      }
  ];
  public appPagesNewRegistration = [
    {
      title: 'Info',
      url: '/newregistration',
      icon: 'information-circle'
    }
  ];

  public appPagesLoggedIn = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    }
    ,{
      title: 'Umtriebsentschädigungen',
      url: '/ue/list',
      icon: 'list'
    }
    ,{
      title: 'Kontrolle starten',
      url: '/kontrollen/selectpp',
      icon: 'car'
    }


  ];

  constructor(
    public authService: AuthService
    , public router: Router
    , public route: ActivatedRoute
    , public us: UserService
    , public ag: AuthGuard
    , public sw: UpdateService
    , public bs: BackendService
    , public gs: GrundstueckeService
    , public es: EinsatzplanService
    , public menu: MenuController
    , public zone: NgZone
  ) {
    defineCustomElements(window);
    const currentRouteConfig = this.router.config.find(f=>f.path == this.router.url.substr(1));
    //-- Navigate after user status changed -------
    this.authService.$userStatus.subscribe(state => {
      if (state == Authenticationstates.AUTHENTICATED) {
        this.menu.enable(true);
        this.appPages.length = 0;
        this.appPages = this.appPages.concat(this.appPagesLoggedIn);

        //-- Load Data ---------
        //console.log('load data');
        this.gs.load();
        this.us.loadACL();
      }

      else if(state == Authenticationstates.REGISTERED){
        this.appPages.length = 0;
        this.appPages = this.appPages.concat(this.appPagesNewRegistration);
        this.router.navigate(['newregistration']);

        this.appPages.push({
          title: 'Hilfe',
          url: '/hilfe',
          icon: 'help'
        });
          
      }
      else if(state == Authenticationstates.APPROVED){
        this.es.load();
        //console.log(this.us.pxuser);
        //-- Für PX Kontrolleure -------
        if(this.us.pxuser.activeforplaning>0 || this.us.pxuser.usergroup_id == 1){

          this.appPages.push({
            title: 'Einsatzplan',
            url: '/ep/list',
            icon: 'calendar'
          });
          this.appPages.push({
            title: 'Verfügbarkeit',
            url: '/ea/list',
            icon: 'ice-cream'
          });

          this.appPages.push({
            title: 'Einstellungen',
            url: '/settings',
            icon: 'cog'
          });
  
        }

        this.appPages.push({
          title: 'Hilfe',
          url: '/hilfe',
          icon: 'help'
        });
  
        
      }
      
      else if(state == Authenticationstates.DEFAULT){
        this.appPages.length = 0;
        this.appPages = this.appPages.concat(this.appPagesLoggedOut);

        //-- clear data ----------
        console.log('clear DATA');
        this.gs.clear();
        this.us.clear();        
        this.es.clear();

        //-- Falls Authguard zu login navigieren -----
        if(currentRouteConfig && currentRouteConfig.canActivate){
          //console.log("go login");
          this.router.navigate(['login']);
        }

      }


    });

    this.initializeApp();

    this.appPages = this.appPages.concat(this.appPagesLoggedOut);
    /*
    this.authService.afAuth.authState.subscribe(user => {
      //console.log("onafAuth");
      if (user) {

      }
      else {

      }
    });
    */
   /*
    this.router.events.subscribe((event: RouterEvent) => {

      if(event && event.url){
        this.activePath = event.url;

      }

    });
*/


  }
  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          const slug = event.url.split(".ch").pop();
          //console.log('hello slug:' + slug);
          if (slug) {
              this.router.navigateByUrl(slug);
          }
      });
    });
  }

  logout(){
    this.us.clear();
    this.menu.enable(false);
    this.authService.SignOut();
  }
}
