/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import {throwError} from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UserService } from './user.service';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private us: UserService ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        if(this.us && this.us.userData){
            let token = this.us.userData.fbToken;
            if (token) {
                //console.log(token);
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                        'Accept': `application/json`,
                        //'Content-Type': `application/json`,
                        'ngsw-bypass': `true`
                    }
                });

                return next.handle(request).pipe( catchError(err => {
                    //console.log(err);
                    if (err.status == 401) {
                        return from(this.us.fbUser.getIdToken(true)).pipe(
                            //tap(token => this.auth.newAccessToken = token), // side effect to set token property on auth service
                            switchMap(newtoken => { // use transformation operator that maps to an Observable<T>
                                request = request.clone({
                                    setHeaders: {
                                        Authorization: `Bearer ${newtoken}`,
                                        'Accept': `application/json`,
                                        //'Content-Type': `application/json`,
                                        'ngsw-bypass': `true`
                                    }
                                });
                                this.us.userData.fbToken = newtoken;
                              return next.handle(request);
                            })
                          );
                    }
                    //console.log("throw error");
                    const error = err.error.message || err.statusText;
                    return throwError(error);
                }));
            }
            else{

                request = request.clone({
                    setHeaders: {
                        'Accept': `application/json`,
                        //'Content-Type': `application/json`,
                        'ngsw-bypass': `true`
                    }
                });
                return next.handle(request);

            }

        }
        else{
            request = request.clone({
                setHeaders: {
                    'Accept': `application/json`,
                    //'Content-Type': `application/json`,
                    'ngsw-bypass': `true`
                }
            });
            return next.handle(request);
        }

    }
}