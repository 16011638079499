/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, NgZone, EventEmitter, Output } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from './backend.service';
import { HttpClient } from '@angular/common/http';
//import {HttpParams} from "@angular/common/http";
import { ToastController } from '@ionic/angular';
import { AuthService } from '../auth/auth.service';
import {Authenticationstates} from '../auth/authenticationstates.enum';
import { observeObject } from '../components/observe-object';
declare const window: any;

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public userData: User;
  public cards;
  public profilecomplete: boolean;
  public providerId: string;
  public fbUser;
  public pxuser;
  public newRegistration: boolean;

  public localsettings: Localsettings;

  public acl: any;
  public employees: any;

  constructor(
     public router: Router
    , private route: ActivatedRoute
    , public bs: BackendService
    , private http: HttpClient
    , public ngZone: NgZone
    , public toastController: ToastController
    , public authService: AuthService

  ) {
    this.userData = null;
    this.pxuser = null;
    this.localsettings = {usepbnameinmap: false, mapview: false, hidedone: false};
    this.newRegistration = false;

    //-- Subscribe to Firebase authstate --------
    this.authService.afAuth.authState.subscribe(user => {
      if (user) {
        //this.userData = user;
        //console.log("user service: signed in");
        //console.log(user);
        if(user.providerData.length>0){
          this.providerId = user.providerData[0].providerId;
        }
        this.fbUser = user;
        this.setUserData(user, this.authService.firstname).then(res => {
          localStorage.setItem('user', JSON.stringify(user));

          const ls = JSON.parse(localStorage.getItem('localsettings'));
          if(ls){
            this.localsettings = ls;
          }

          this.authService.$userStatus.emit(Authenticationstates.AUTHENTICATED);
        });

      } else {
        this.stopRefreshTokenTimer();
        this.authService.$userStatus.emit(Authenticationstates.DEFAULT);
        this.clear();
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));

      }
    });


    //-- Assign new userState to userState
    this.authService.$userStatus.subscribe(state => {
      //-- load px user data --------------
      if(this.authService.userStatus == Authenticationstates.AUTHENTICATED){
        this.http.get(this.bs.baseurl+'users/viewbyprovideruid')
        .subscribe((data: any) => {
          const response = data;
          if(response.success){
            this.pxuser = response.data;
            //-- if account not approved, navigate to infopage
            if(this.pxuser.approved==0){
              this.authService.$userStatus.emit(Authenticationstates.REGISTERED);
            }
            else{
              this.authService.$userStatus.emit(Authenticationstates.APPROVED);
            }
          }
          else{
            this.bs.showToast('Fehler beim Laden des Benutzers. Bitte bei parkix melden.', 5000, 'danger');
            this.authService.SignOut();
          }
        }, error => {
          console.error(error);
          console.log('HTTP Error, no answer');
        });

      }
    });
  }

  public localSettingsChanged($event){
    localStorage.setItem('localsettings', JSON.stringify(this.localsettings));
  }

  public handleAfterLogin(){
    //console.log("handleAfterLogin");
    if(this.newRegistration){
      this.router.navigate(['newregistration']);
      return;
    }
    this.router.navigate(['home']);
  }


  public setUserData(user: any, firstname: string): Promise<any>{
    this.userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      internaluser: null,
      fbToken:null,
      firstname

    };
    //console.log("set user data");
    //console.log(this.userData);
    return new Promise((resolve, reject) => {
      user.getIdToken()
      .then(idToken => {
          this.userData.fbToken = idToken;
          //console.log(idToken);
          this.startRefreshTokenTimer(idToken);
          resolve(true);
        });
    });
  }


  public clear(){
    this.userData = null;
    this.pxuser = null;
    this.localsettings = {usepbnameinmap: false, mapview: false, hidedone: false};
    localStorage.setItem('user', null);
    localStorage.setItem('localsettings', null);

  }

  public async update(pbkunde){
    //console.log(this.userData);
    console.log('todo update');

  }

  public updateLocalsettings(changes){
    console.log('updateLocalsettings');
    //console.log(changes);
  }

  refreshToken() {
    this.authService.afAuth.currentUser.then(user => {
      user.getIdToken(/* forceRefresh */ true).then( (idToken) => {
          this.userData.fbToken = idToken;
          this.startRefreshTokenTimer(idToken);
        }).catch((_error) => {
          // Handle error
        });
  });

}
  private refreshTokenTimeout;

  private startRefreshTokenTimer(token) {
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(atob(token.split('.')[1]));

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      //console.log(expires);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      //console.log(timeout);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private stopRefreshTokenTimer() {
      clearTimeout(this.refreshTokenTimeout);
  }

  public loadACL() {
    if (this.acl) {
      return Promise.resolve(this.acl);
    }
    return new Promise(resolve => {

        this.http.get(this.bs.baseurl+'users/acl').subscribe((res: any) => {
          this.acl = res.data;
          //console.log('acl:'+res);
          resolve('');
        }, error => {
          console.log('HTTP Error, no answer acl user-data.ts');
          console.log(error);
      });

    });
  }

  public isAllowed(module,action){
    if(this.acl == null){
      return false;
    }

    for(let i=0; i<this.acl.length; i++){
      if(this.acl[i].module_id == module){
        if(this.acl[i][action] == 1)
        {
          return true;
        }
        else{
          return false;
        }
      }
    }//for
    return false;
  }

  public  employeeNameById(benutzer_id: any){
    for(let i=0; i<this.employees.length; i++){
      if(this.employees[i].userid == benutzer_id){
        return this.employees[i].name;
      }
    }//for    
    return benutzer_id;
  }

}
export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
  internaluser: any;
  fbToken: any;
  firstname: string;
}

export interface Localsettings {
  usepbnameinmap: boolean;
  mapview: boolean;
  hidedone: boolean;
}

