import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import * as AuthEnums from '../auth/authenticationstates.enum';
@Injectable({
  providedIn: 'root'
})

export class AuthGuard  {

  constructor(
    public authService: AuthService,
    public router: Router
  ){ }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //console.log("isloggedin:");
    //console.log(this.authService.isLoggedIn);
    if(this.authService.isLoggedIn !== true) {
      this.router.navigate(['login']);
      return false;
    }
    else if(this.authService.userStatus == AuthEnums.Authenticationstates.AUTHENTICATED){
      /*
      if(!this.us.profilecomplete){
        this.router.navigate(['profile']);
        return false;
      }
      */

    }
    return true;
  }

}
